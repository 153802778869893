import React from 'react'
import useFirestore from '../hooks/useFirestore'
import { motion } from 'framer-motion'

const ImageGrid = ({ setSelectedImg, cat }) => {
    const { docs } = useFirestore(cat)

    return (
        <div className="py-1 cli grid">
            { docs && docs.map(doc => (
                <motion.div style={
                    { overflow: "hidden",
                    height: "0px",
                    padding: "30% 0",
                    position: "relative",
                    opacity: "0.8",
                    }

                    } key={doc.id}
                    layout
                    whileHover={{ opacity: 1 }}
                    onClick={() => setSelectedImg(doc.url)}
                    >
                    <div style={
                        {
                        textAlign: "center",
                        minWidth: "50%",
                        minHeight: "50%",
                        maxWidth: "150%",
                        position: "absolute",
                        top:"0",
                        left: "0",
                        }
                        }
                    >
                        <motion.img src={doc.url} alt="uploaded pic"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 1 }}
                            width="70%"
                        />
                        <h4>{doc.name}</h4>
                    </div>
                </motion.div>
            )) }
        </div>
    )
}

export default ImageGrid