import React, { useState } from 'react'
import ProgressBar from './ProgressBar'

const UploadForm = (cat) => {

    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)

    const types = ['image.png', 'image/jpeg']


    const changeHandler = (e) => {
        let selected = e.target.files[0]
        
        if (selected && types.includes(selected.type)) {
            setFile(selected)
            setError('')
        } else {
            setFile(null)
            setError('Please select an image file (png or jpeg)')
        }
    }

    return (
        <form>
            <label style={
                {
                display: "block",
                width: "30px",
                height: "30px",
                border: "1px solid black",
                borderRadius: "50%",
                margin: "10px auto",
                lineHeight: "30px",
                color: "grey",
                fontWeight: "bold",
                fontsize: "24px",
                }
            }>
                <input style={
                    {
                    height: "0",
                    width: "0",
                    opacity: "0",
                    }}
                    type="file"
                    onChange={changeHandler}
                />
            </label>
            <div className="output">
                { error && <div className="error">{ error }</div> }
                { file && <div>{ file.name }</div> }
                { file && <ProgressBar file={file} setFile={setFile} cat={cat}/>}
            </div>
        </form>
    )
}

export default UploadForm
