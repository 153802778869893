import React from 'react'
import { motion } from 'framer-motion'

const Modal = ({ selectedImg, setSelectedImg }) => {

    const handleClick = (e) => {
        if (e.target.classList.contains('backdrop'))
        setSelectedImg(null)
    }
    
    return (
        <motion.div className="backdrop"
        style={
            {
            position: "fixed",
            textAlign: "center",
            paddingTop: "20",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)"
            }
        }
        onClick={handleClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        >
            <motion.img src={ selectedImg } alt="enlarged pic"
            initial={{ y: "-100vh" }}
            animate={{ y: "0" }}
            width="85%"
            />
        </motion.div>
    )
}

export default Modal