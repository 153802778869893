import React, { useState, useEffect } from 'react'
import { useAuth } from './contexts/AuthContext'

import Logo from './logo.png'
import UploadForm from './components/UploadForm';
import ImageGrid from './components/ImageGrid';
import Modal from './components/Modal';

export default function Wedding() {

    const [ error, setError ] = useState("")
    const [ selectedImg , setSelectedImg] = useState(null)
    const [ isHidden , setIsHidden] = useState(true)

    const { logout } = useAuth()
    const { currentUser } = useAuth()
    async function handleLogout(){
        setError('')

        try {
            await logout()
        } catch {
            setError('Failed to log out')
            console.log(error)
        }
        
    }
    const hidden = () => {
        if (currentUser.uid === "FSiW8ODpfHZUsilwUkrH4go25hf2") {
            setIsHidden(false)
        } else {
            setIsHidden(true)
        }
    }
    
    useEffect(() => {
        hidden()
    })
    
    return (
    <>
        <div className="navbar">
            <div className="container flex">
                <h1 className="mx-1 logo">Pacs.Xipitei</h1>
                <img className="imgLogo" src={Logo} alt="Logo"/>
                <nav>
                    <ul>
                        <button variant="link" onClick={handleLogout} className="btn btn-primary">Déconnecter</button>
                    </ul>
                </nav>
            </div>
        </div>
        <div>
            .
        </div>
        <div className="Jeff">
            <div className="navbar">
                <div className="container flex ">
                    <h1 className="mx-2 logo md">Famille Jeff</h1>
                </div>
            </div>
                {isHidden === false ?
                    <UploadForm cat="Cote_Jeff"/> :
                    <></>
                }
                <ImageGrid setSelectedImg={setSelectedImg} cat="Cote_Jeff"/>
                { selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg}/>}
            <div className="">
            </div>
        </div>
        <div className="Franck">
            <div className="navbar">
                <div className="container flex">
                    <h1 className="mx-2 logo md">Famille Franck</h1>
                </div>
            </div>
                {isHidden === false ?
                    <UploadForm cat="Cote_Franck"/> :
                    <></>
                }
                <ImageGrid setSelectedImg={setSelectedImg} cat="Cote_Franck"/>
                { selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg}/>}
            <div className="">
            </div>
        </div>
        <div className="groupe">
            <div className="navbar">
                <div className="container flex">
                    <h1 className="mx-2 logo md">Group</h1>
                </div>
            </div>
                {isHidden === false ?
                    <UploadForm cat="Groupe"/> :
                    <></>
                }
                <ImageGrid setSelectedImg={setSelectedImg} cat="Groupe"/>
                { selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg}/>}
            <div className="">
            </div>
        </div>
        <div className="Tables">
            <div className="navbar">
                <div className="container flex">
                    <h1 className="mx-2 logo md">Les Tables</h1>
                </div>
            </div>
                {isHidden === false ?
                    <UploadForm cat="Les_Tables"/> :
                    <></>
                }
                <ImageGrid setSelectedImg={setSelectedImg} cat="Les_Tables"/>
                { selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg}/>}
            <div className="">
            </div>
        </div>
        <div className="Divers">
            <div className="navbar">
                <div className="container flex">
                    <h1 className="mx-2 logo md">Divers</h1>
                </div>
            </div>
                {isHidden === false ?
                    <UploadForm cat="Divers"/> :
                    <></>
                }
                <ImageGrid setSelectedImg={setSelectedImg} cat="Divers"/>
                { selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg}/>}
            <div className="">
            </div>
        </div>
    </>
    )
}
