import { AuthProvider } from "./contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Wedding from "./Wedding"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"

function App() {
  return (
      <Router>
        <AuthProvider>
          <Switch>
            <PrivateRoute exact path="/" component={Wedding}/>
            <Route path="/login" component={Login}/>
          </Switch>
        </AuthProvider>
      </Router>
  );

}

export default App;
