import React, { useEffect } from 'react'
import useStorage from '../hooks/useStorage'
import { motion } from 'framer-motion'

const ProgressBar = ({ file, setFile, cat }) => {
    const { url, progress } = useStorage(file, cat);

    useEffect (() => {
        if (url) {
            setFile(null)
        }
    }, [url, setFile])

    return (
        <motion.div style={
            { width: progress + '%',
            height: "5px",
            background: "blue",
            margintop: "20px" }
        }
        initial={{ width: 0}}
        animate={{ width: progress + '%'}}
        ></motion.div>
    )
}

export default ProgressBar