import { useState, useEffect } from "react"
import { projectStorage, projectFirestore, timestamp } from '../firebase'

const useStorage = (file, cat) => {
    const [progress , setProgress] = useState(0)
    const [error, setError] = useState(null)
    const [url, setUrl] = useState(null)

    useEffect(() => {
        // references
        const storageRef = projectStorage.ref(file.name)
        const collectionRef = projectFirestore.collection(cat.cat)
        console.log(file.name, cat.cat)

        storageRef.put(file).on('state_changed', (snap) => {
            let percentage = (snap.bytesTransferred / snap.totalBytes) * 100
            setProgress(percentage)
        }, (error) => {
            setError(error)
        }, async () => {

            const url = await storageRef.getDownloadURL()
            const createdAt = timestamp()
            const name = await storageRef.name
            collectionRef.add({url, createdAt, name })
            setUrl(url)
        })
    }, [file, cat])

    return { progress, url, error }

}

export default useStorage