import React, { useState} from "react"
import { useForm } from "react-hook-form"
import { useAuth } from "./contexts/AuthContext"
import { useHistory } from "react-router-dom"

import Logo from './logo.png'

function Login() {
    const { login } = useAuth()
    const [ error, setError ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const history = useHistory()

    const {register, handleSubmit, errors} = useForm({
        mode: 'onTouched'
    })
    
    async function onSubmit(data) {
        try {
            console.log("Onsubmit")
            setError('')
            setLoading(true)
            await login(data.Email,data.Password)
            history.push("/")
        } catch {
            setError("failed to sign in")
        }
        setLoading(false)
    }
    
    return (
    <>
        <div className="navbar">
            <div className="container flex">
              <h1 className="mx-1 logo">Pacs Xipitei</h1>
              <img className="imgLogo" src={Logo} alt="Logo"/>
              <nav>
                  <ul>
                      {/* <li><a href="https://xipitei.com">Home</a></li> */}
                  </ul>
              </nav>
            </div>
        </div>
        <form className="showcase" onSubmit={handleSubmit(onSubmit)}>
            <div className="container grid">
                <div className="showcase-form card">
                    <h2>Identification</h2>
                    {/* {JSON.stringify(currentUser)} */}
                    {error}
                    <div>
                        <div className="form-control">
                            <label htmlFor="Email">Email</label>
                            <input type="text" name="Email" id="Email" ref={register({required: 'Vous devez entrer une Email'})}/>
                            {errors.Email && <span>{errors.Email.message}</span>}
                        </div>
                        <div className="form-control">
                        <label htmlFor="Password">Password</label>
                            <input type="text" name="Password" id="Password" ref={register({required: 'Vous devez entrer une mot de passe', minLength: {value: 6, message: 'Vous devez rentrer 6 caractères'}})}/>
                            {errors.Password && <span>{errors.Password.message}</span>}
                        </div>
                        {/* <div className="form-control">
                            <label htmlFor="passwordConfirm">Password Confirmation</label>
                            <input type="text" name="passwordConfirm" id="passwordConfirm" ref={register({required: 'Vous devez entrer une mot de passe', minLenght: {value: 6, message: 'Vous devez rentrer 6 caractères'}})}/>
                            {errors.passwordConfirm && <span>{errors.passwordConfirm.message}</span>}
                        </div> */}
                        <button disabled={loading} className="btn btn-primary">Envoyer</button>
                    </div>
                </div>
            </div>
        </form>
    </>

    )
}

export default Login
